<template>
  <b-card border-variant="primary">
    <b-card-header>
      <b-card-title>
        <feather-icon icon="FileIcon" size="20" class="mr-1" />
        Información del documento
      </b-card-title>
    </b-card-header>

    <b-card-body>
      <b-card-text style="font-size: 14px; text-align: justify;">
        Selecciona a continuación el tipo de documento e ingresa el número de
        documento de la persona que recibirá el pedido.
      </b-card-text>

      <div class="divider" />

      <validation-observer ref="form">
        <form class="flex-container">
          <validation-provider
            v-slot="{ errors }"
            name="documentType"
            rules="required"
          >
            <RadioGroup
              v-model="person.documentType"
              :options="documentTypes"
              label="Tipo de documento:"
              :reduce="(option) => option.id"
              radio-label="name"
              name="documentType"
            />

            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>

          <validation-provider
            v-slot="{ errors }"
            name="documentNumber"
            :rules="{ required: true, numeric: true, max: 10 }"
          >
            <b-form-group label="Número de documento:">
              <b-form-input
                v-model="person.documentNumber"
                placeholder="312312312"
                required
              />

              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </form>
      </validation-observer>
    </b-card-body>
  </b-card>
</template>

<script>
import { TabContent } from "@components/vue-form-wizard-lazy";

import { ValidationObserver, ValidationProvider } from "vee-validate";
import { numeric, required } from "@validations";

import { mapState, mapActions, mapMutations } from "@/store/deliveries";

import RadioGroup from "../components/RadioGroup.vue";

export default {
  components: {
    // Vee validate
    ValidationObserver,
    ValidationProvider,

    // Form wizard
    TabContent,

    RadioGroup,
  },
  data() {
    return {
      documentTypes: [],
      shouldSearchPerson: false,
    };
  },
  watch: {
    "person.documentNumber"() {
      this.shouldSearchPerson = true;
    },
    "person.documentType"() {
      const newDocumentType = this.documentTypes.find(
        (item) => item.id === this.person.documentType
      );

      this.setPerson({
        ...this.person,
        documentAcronym: newDocumentType.acronym,
      });
    },
  },
  computed: {
    ...mapState(["person"]),
  },
  methods: {
    ...mapMutations(["setPerson"]),
    ...mapActions(["searchPerson"]),

    async getDocumentTypes() {
      await this.$http
        .get("/documenttypes")
        .then(({ data: { document_types } }) => {
          if (!document_types) return;

          const options = document_types.map((item) => ({
            id: item.id,
            name: item.description,
            acronym: item.name,
          }));

          this.documentTypes = options;
        });
    },

    async validate() {
      const isValid = await this.$refs.form.validate();

      if (isValid && this.shouldSearchPerson) {
        await this.searchPerson();
      }

      return isValid;
    },
  },
  created() {
    this.getDocumentTypes();
  },
};
</script>

<style scoped>
.flex-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.divider {
  border-bottom: 1px solid #e2e8f0;
  margin: 1.5rem 0;
}
</style>
