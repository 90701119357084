<template>
  <div class="input-container">
    <b-form-input
      class="obfuscated-input"
      v-model="inputValue"
      :placeholder="placeholder"
      :disabled="disabled"
    />

    <button
      type="button"
      class="edit-button"
      v-if="disabled"
      @click="toggleEditMode"
    >
      <feather-icon icon="Edit2Icon" />
    </button>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      editMode: !this.editable,
    };
  },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
    disabled() {
      return !this.editMode;
    },
  },
  methods: {
    toggleEditMode() {
      this.editMode = true;
      this.inputValue = "";
    },
  },
};
</script>

<style scoped>
.input-container {
  position: relative;
}

.edit-button {
  position: absolute;
  right: 0.5rem;
  top: 50%;
  transform: translateY(-50%);

  display: grid;
  place-items: center;

  border: none;
  background: none;

  height: 2rem;
  width: 2rem;
}
</style>
