<template>
  <fieldset>
    <legend v-if="label" class="radio-group-label">{{ label }}</legend>

    <div class="radio-container">
      <label
        v-for="(option, index) in options"
        :key="index"
        class="radio-label"
      >
        <b-button
          class="radio-button"
          variant="outline-primary"
          :class="{ active: selectedOption === reduce(option) }"
          @click="handleOptionClick(reduce(option))"
        >
          <div>
            <feather-icon v-if="option.icon" :icon="option.icon" size="16" />

            {{ option[radioLabel] }}
          </div>

          <div v-if="option.description">
            <feather-icon
              icon="InfoIcon"
              size="20"
              :id="`${name}-option-description-${option.id}`"
            />

            <b-tooltip
              :target="`${name}-option-description-${option.id}`"
              triggers="hover"
            >
              {{ option.description }}
            </b-tooltip>
          </div>

          <input
            type="radio"
            :value="reduce(option)"
            v-model="selectedOption"
            :name="name"
            style="display: none"
          />
        </b-button>
      </label>
    </div>
  </fieldset>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: "",
    },
    options: {
      type: Array,
      required: true,
    },
    value: {
      type: [String, Number, null],
      default: null,
    },
    name: {
      type: String,
      required: true,
    },
    radioLabel: {
      type: String,
      default: "label",
    },
    reduce: {
      type: Function,
      default: (option) => option.value,
    },
  },
  methods: {
    handleOptionClick(optionValue) {
      this.selectedOption = optionValue;
    },
  },
  computed: {
    selectedOption: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>

<style scoped>
.radio-group-label {
  font-size: 1rem;
  font-weight: 400;
  
  margin-bottom: 10px;
  display: block;
}

.radio-label {
  margin-right: 10px;
  display: block;
  flex: 1 1 auto;
}

.radio-button {
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.2s ease-in-out;
}

.radio-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.5rem;
}
</style>
