<template>
    <b-card border-variant="primary">
        <div class="flex-container">
            <b-card-body class="p-2">
                <p class="mt-1">
                    Selecciona la fecha maxima para la entrega del pedido
                </p>

                <div class="mt-2">
                    <feather-icon class="float-right" icon="InfoIcon" size="20" v-b-tooltip.hover
                        title="Tiempo máximo que dispones para la entrega de tu pedido." />

                    <label for="shipment-max-date" class="text-md"> Fecha máxima de espera: </label>
                    <b-form-datepicker v-bind="calendarParams" v-model="$maxWaitingDate" menu-class="w-100"
                        calendar-width="100%" />
                </div>
            </b-card-body>

            <b-card class="mb-0 p-2" bg-variant="primary" text-variant="white">
                <div class="d-flex align-items-center mb-1">
                    <feather-icon icon="TruckIcon" size="16" class="mr-1" />
                    <h2 class="text-white mb-0">Fecha de entrega</h2>
                </div>

                <div v-if="shipmentInfo !== null" class="mt-2">
                    <b-card-text>
                        Dirección: <b>{{ shipmentInfo.address }}</b>
                    </b-card-text>

                    <b-card-text>
                        Valor: <b>${{ shipmentInfo.price }}</b>
                    </b-card-text>
                </div>

                <b-card-text v-else class="mt-2">
                    Debes seleccionar una fecha limite de entrega, esto quiere decir cuanto tiempo disponemos crear tu pedido y enviarlo.
                </b-card-text>
            </b-card>
        </div>
    </b-card>
</template>

<script>
import vSelect from "vue-select";

import { mapState, mapActions, mapMutations } from "@/store/deliveries";

import RadioGroup from "../components/RadioGroup.vue";

export default {
    components: {
        vSelect,
        RadioGroup,
    },
    data() {
        return {
            placeId: null,
            places: [],
        };
    },
    watch: {
        placeId(placeId) {
            const selectedPlace = this.places.find((item) => item.id === placeId);
            this.updateShipmentInfo(selectedPlace);
        },
    },
    computed: {
        ...mapState(["customerShipmentType", "customer", "shipmentInfo", "maxWaitingDate"]),
        calendarParams() {
            const today = new Date();
            return {
                locale: 'es',
                placeholder: 'Selecciona una fecha',
                'date-format': 'DD/MM/YYYY',
                min: today,
                max: new Date(today.getFullYear(), today.getMonth(), today.getDate() + 14),
            }
        },

        isGroupDelivery() {
            return this.customerShipmentType?.group === 'colectivo';
        },

        $maxWaitingDate: {
            get: function () { return this.maxWaitingDate },
            set: function (maxWaitingDate) { this.setMaxWaitingDate(maxWaitingDate) }
        },
    },
    methods: {
        ...mapMutations(["setMaxWaitingDate"]),
        ...mapActions(["updateShipmentInfo"]),

        async getShipmentTypeSettings() {
            if (this.customerShipmentType?.settingsType !== "basic") return;

            const url = `customers/${this.customer.id}/shipment-types/${this.customerShipmentType.shipmentTypeId}/settings`;

            await this.$http.get(url).then((response) => {
                const {
                    data: { parametrizations },
                } = response;

                if (parametrizations?.length > 0) {
                    this.places = parametrizations.map((item) => ({
                        id: item.id,
                        shipmentType: item.shipmentType,
                        place: item.place,
                        address: item.address,
                        price: item.price,
                        minimumRequests: item.minimumRequests,
                        schedules: item.schedules,
                    }));
                } else {
                    this.places = [];
                }
            });
        },

        validate() {
            const errors = [];

            if (this.placeId === null) {
                errors.push('Debes seleccionar un lugar de entrega');
            }

            if (this.isGroupDelivery && !this.maxWaitingDate) {
                errors.push('Debes seleccionar una fecha máxima de espera');
            }

            errors.forEach(error => {
                this.$bvToast.toast(error, {
                    title: "Error",
                    variant: "danger",
                    solid: true,
                });
            })

            return errors.length === 0;
        },

    async initializeStep() {
            if (!this.customerShipmentType) {
                return;
            }

            await this.getShipmentTypeSettings();
            this.placeId = this.shipmentInfo?.id || null;
        },
    },
    created() {
        this.initializeStep();
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<style scoped>
.flex-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 2rem;
}

.flex-container>* {
    flex: 1 1 45%;
}
</style>
