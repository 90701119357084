<template>
  <b-card border-variant="primary">
    <div>
      <gmap-autocomplete class="introInput search-input-location" @place_changed="setPlace">
        <template v-slot:input="slotProps">
          <v-text-field
            outlined
            prepend-inner-icon="place"
            placeholder="Location Of Event"
            ref="input"
            v-bind="slotProps.attrs"
            v-on="slotProps.listeners"
          >
          </v-text-field>
        </template>
      </gmap-autocomplete>

      <b-button variant="primary" class="mb-1 font-wizard" @click="toggleSelectMode" style="width: 100%;">
        {{ selectMode ? 'Deshabilitar la selección manual' : 'Seleccionar ubicación manualmente' }}
      </b-button>

      <b-card v-if="customerLocation.selectedPlace && customerLocation.selectedPlace.geometry && typeof customerLocation.selectedPlace.geometry.location.lat === 'function'" border-variant="success">
        <b-card-header><strong>Detalles de la ubicación seleccionada</strong></b-card-header>
        <b-card-body>
          <b-card-text>
            <strong>Dirección:</strong> {{ customerLocation.selectedPlace.formatted_address }}<br>
            <strong>Latitud:</strong> {{ customerLocation.selectedPlace.geometry.location.lat() }}<br>
            <strong>Longitud:</strong> {{ customerLocation.selectedPlace.geometry.location.lng() }}<br>
            <strong>Costo de envío:</strong> $ {{ customerLocation.calculatedCost }}<br>
            <strong>Distancia:</strong> {{ customerLocation.distanceInKm }} km
          </b-card-text>
        </b-card-body>
      </b-card>

      <GmapMap
        :center="center"
        :zoom="13"
        style="width: 100%; height: 500px"
        :options="mapOptions"
        @click="mapClicked"
      >
        <GmapPolygon
          v-for="(polygon, index) in polygons"
          :key="index"
          :paths="polygon.paths"
          :options="{ fillColor: '#009EE0', fillOpacity: 0.25, strokeWeight: 1, clickable: false }"
        />
        <!-- Marcador de la tienda con ícono personalizado -->
        <GmapMarker v-if="center.lat && center.lng"
                    :position="center"
                    :icon="{
                      url: 'https://mimedicamento.com.co/img/logo.3a7c8281.svg',
                      scaledSize: { width: 32, height: 32 }
                    }"
        />
        <!-- Marcador de la ubicación del cliente -->
        <GmapMarker v-if="customerLocation.customerMarker" :position="customerLocation.customerMarker" :animation="markerAnimation" />

        <!-- Línea de ruta entre la tienda y el cliente -->
        <GmapPolyline v-if="customerLocation.directions"
                      :path="customerLocation.directions.routes[0].overview_path"
                      :options="{ strokeColor: '#FF0000', strokeOpacity: 1.0, strokeWeight: 3 }"
        />
      </GmapMap>
    </div>
  </b-card>
</template>

<script>
import { gmapApi } from 'vue2-google-maps';
import { mapState, mapMutations, mapActions } from "@/store/deliveries";

export default {
  data() {
    return {
      center: { lat: 0, lng: 0 },
      polygons: [],
      address: '',
      geocoder: null,
      markerAnimation: null,
      success: false,
      error: false,
      googleLoaded: false,
      selectMode: false,
      mapOptions: {
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: true,
        disableDefaultUi: false,
      },
    };
  },
  computed: {
    ...mapState(["customerLocation", "customer"]),
    google() {
      return gmapApi() || null;
    }
  },
  created() {
    this.getCustomerLocation();
    this.getCustomerCoverages();
  },
  mounted() {
    if (this.google && this.google.maps) {
      this.initializeGoogleMapsData();
    } else {
      this.$watch(
        () => this.google,
        (google) => {
          if (google && google.maps) {
            this.initializeGoogleMapsData();
          }
        }
      );
    }
  },
  methods: {
    ...mapMutations(["setCustomerLocation"]),
    getCustomerLocation() {
      this.$http.get('/customers/get-location/' + this.customer.id, this.formData)
        .then((response) => {
          this.center = response.data.customerLocation.location; // Configuración de la ubicación de la tienda
        });
    },

    initializeGoogleMapsData() {
      if (this.customerLocation.selectedPlace && this.customerLocation.selectedPlace.geometry) {
        // Verifica que lat y lng sean funciones
        if (typeof this.customerLocation.selectedPlace.geometry.location.lat !== 'function') {
          const location = this.customerLocation.selectedPlace.geometry.location;
          this.customerLocation.selectedPlace.geometry.location = new this.google.maps.LatLng(location.lat, location.lng);
        }
      }

      this.googleLoaded = true;
      this.geocoder = new this.google.maps.Geocoder();
    },

    getCustomerCoverages() {
      this.$http.get('/customers/get-coverages/' + 1, this.formData)
        .then((response) => {
          this.polygons = response.data.customerCoverages;
        });
    },

    toggleSelectMode() {
      this.selectMode = !this.selectMode;
      this.mapOptions = {
        ...this.mapOptions,
        draggableCursor: this.selectMode ? 'crosshair' : null,
      };
    },
    setPlace(place) {
      if (!place.geometry) {
        console.log("No details available for input: '" + place.name + "'");
        return;
      }

      const location = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng()
      };

      // Verificar si la coordenada está dentro de algún polígono
      const polygonData = this.getPolygonDataForCoordinate(location);

      let deliveryCost = null;
      if (polygonData) {
        this.success = true;
        this.error = false;
        deliveryCost = polygonData.deliveryCost;
      } else {
        this.success = true;
        this.error = false;
        deliveryCost = this.distanceInKm * 1000; // Costo basado en la distancia cuando está fuera de cobertura
      }

      // Calcular la ruta y la distancia entre la tienda y el cliente
      this.calculateRoute(location, place, deliveryCost);
    },
    getPolygonDataForCoordinate(coordinate) {
      if (!this.googleLoaded) return null;

      const google = this.google;
      for (const polygon of this.polygons) {
        const gPolygon = new google.maps.Polygon({ paths: polygon.paths });
        const latLng = new google.maps.LatLng(coordinate.lat, coordinate.lng);
        if (google.maps.geometry.poly.containsLocation(latLng, gPolygon)) {
          return polygon;
        }
      }
      return null;
    },
    async searchAddress() {
      const result = await this.geocodeAddress(this.address);
      const location = result[0].geometry.location;
      const polygonData = this.getPolygonDataForCoordinate({ lat: location.lat(), lng: location.lng() });

      let deliveryCost = null;
      if (polygonData) {
        this.success = true;
        this.error = false;
        deliveryCost = polygonData.deliveryCost;
      } else {
        this.success = true;
        this.error = false;
        deliveryCost = this.distanceInKm * 1000; // Costo basado en la distancia cuando está fuera de cobertura
      }

      // Calcular la ruta y la distancia entre la tienda y el cliente
      this.calculateRoute(location, result[0], deliveryCost);
    },
    geocodeAddress(address) {
      return new Promise((resolve, reject) => {
        this.geocoder.geocode({ address }, (results, status) => {
          if (status === 'OK') {
            resolve(results);
          } else {
            reject(new Error('Geocode was not successful for the following reason: ' + status));
          }
        });
      });
    },
    mapClicked(event) {
      if (!this.selectMode) return;

      const location = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng()
      };

      // Geocodificar la ubicación seleccionada manualmente
      this.geocoder.geocode({ location }, (results, status) => {
        if (status === 'OK' && results[0]) {
          const polygonData = this.getPolygonDataForCoordinate(location);

          let deliveryCost = null;
          if (polygonData) {
            this.success = true;
            this.error = false;
            deliveryCost = polygonData.deliveryCost;
          } else {
            this.success = true;
            this.error = false;
            deliveryCost = this.distanceInKm * 1000; // Costo basado en la distancia cuando está fuera de cobertura
          }

          // Calcular la ruta y la distancia entre la tienda y el cliente
          this.calculateRoute(location, results[0], deliveryCost);
        } else {
          this.success = false;
          this.error = true;
          this.setCustomerLocation({
            customerMarker: null,
            distanceInKm: null,
            calculatedCost: null,
            directions: null,
            selectedPlace: null,
          });
        }
      });

      // Animar el marcador
      this.markerAnimation = this.google.maps.Animation.BOUNCE;
      setTimeout(() => {
        this.markerAnimation = null;
      }, 750);
    },
    calculateRoute(location, place, deliveryCost) {
      const directionsService = new this.google.maps.DirectionsService();

      directionsService.route(
        {
          origin: this.center,
          destination: location,
          travelMode: this.google.maps.TravelMode.DRIVING, // Puedes cambiar a BICYCLING, WALKING, etc.
        },
        (response, status) => {
          if (status === this.google.maps.DirectionsStatus.OK) {
            const route = response.routes[0];
            let totalDistance = 0;
            route.legs.forEach((leg) => {
              totalDistance += leg.distance.value; // Distancia en metros
            });

            const distanceInKm = (totalDistance / 1000).toFixed(2); // Convertir a kilómetros y redondear

            // Si estamos fuera de la cobertura, calcular el costo basado en la distancia
            if (!deliveryCost) {
              deliveryCost = distanceInKm * 1000; // Multiplicar por 1000 para obtener el costo
            }

            // Actualizar el estado en el store de Vuex
            this.setCustomerLocation({
              customerMarker: location,
              distanceInKm: distanceInKm,
              calculatedCost: deliveryCost,
              directions: response,
              selectedPlace: place,
            });
          } else {
            console.error('Error fetching directions: ' + status);
          }
        }
      );
    },
  },
};
</script>

<style scoped>
.search-input-location {
  width: 100%;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #009EE0 !important;
  border-radius: 0.300rem;
}

@media (max-width: 768px) {
  .font-wizard {
    font-size: 10px;
  }
}
</style>
      