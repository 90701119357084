<template>
    <div>
        <slot name="header">
            <p class="bold text-lg">
                <b-icon v-if="icon" :icon="icon" />
                {{title}}
            </p>
        </slot>
        <slot />
    </div>
</template>

<script>
    export default {
        props: {
            icon: {
                type: String,
                default: null,
            },
            title: {
                type: String,
                default: 'Título'
            },
        }
    }
</script>
