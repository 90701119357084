<template>
    <b-card border-variant="primary">
        <p class="bold">Asegurate de tener los medicamentos necesarios.</p>
        <p class="mb-0 text-right"><b>Farmacia: </b> {{customer.name}}</p>

        <b-table
            :items="cums"
            :fields="fields"
            striped
            hover
            responsive
        >
            <template #cell(actions)="row">
                <div class="d-flex justify-content-center align-items-center gap-2">
                    <button class="btn p-0" @click="increaseAmount(row)" v-b-tooltip.hover title="Incrementar pedido"> 
                        <b-icon icon="plus-square-fill" /> 
                    </button>
                    
                    <button class="btn p-0" @click="decreaseAmount(row)" v-b-tooltip.hover title="Disminuir pedido">
                        <b-icon icon="dash-square-fill" /> 
                    </button>

                    <button class="btn text-danger p-0" @click="deleteAmount(row)" v-b-tooltip.hover title="Eliminar medicamento"> 
                        <b-icon icon="trash-fill" />
                    </button>
                </div>
            </template>
        </b-table>
    </b-card>
</template>

<script>
    import { mapState, mapMutations } from "@/store/deliveries";

    export default {
        data(){
            return {
                fields: [
                    {
                        key: 'name',
                        label: 'Nombre',
                        sortable: true,
                    },
                    {
                        key: 'description',
                        label: 'Descripción',
                    },
                    {
                        key: 'amount',
                        label: 'Cantidad',
                        sortable: true,
                        class: 'text-center',
                    },
                    {
                        key: 'actions',
                        label: 'Acciones',
                    }
                ]
            }
        },
        computed: {
            ...mapState(["cumStock", "customer"]),

            cums(){
                return Object.values(this.cumStock).map((stock) => ({
                    name: stock.cum.producto,
                    description: stock.cum.descripcioncomercial,
                    amount: stock.amount,
                    stock_id: stock.stock_id,
                }));
            },
        },
        methods:{
            ...mapMutations(['increaseCumStock', 'setCumStock']),

            increaseAmount(row){
                const {stock_id}= row.item;
                this.increaseCumStock({stock_id, increase:1});
            },

            decreaseAmount(row){
                const {stock_id}= row.item;
                this.increaseCumStock({stock_id, increase: -1});
            },

            deleteAmount(row){
                const {stock_id}= row.item;

                this.$bvModal.msgBoxConfirm(
                    'Una vez eliminado el medicamento, sólo podrá añadirlo volviendo a buscarlo desde la anterior ventana.', 
                    {
                        title: '¿Está seguro que desea eliminarlo?',
                        size: 'sm',
                        buttonSize: 'sm',
                        okVariant: 'danger',
                        okTitle: 'SI',
                        cancelTitle: 'NO',
                        centered: true
                    }
                )
                .then(value => {
                    if(value){
                        this.setCumStock({stock_id, amount: -1});
                    }
                })
                .catch(err => {
                    // An error occurred
                })
            }
        }
    }
</script>
