<template>
  <b-card border-variant="primary">
    <b-card-header>
      <b-card-title>Selecciona el tipo de envío</b-card-title>
    </b-card-header>

    <b-card-body>
      <validation-observer ref="form">
        <validation-provider v-slot="{ errors }" :rules="{ required: true }" class="col-12 col-sm-6"
          name="generalShipmentType">
          <div>
            <RadioGroup label="Tipo de envío general:" :options="generalShipmentTypeOptions"
              v-model="form.generalShipmentType" name="generalShipmentType" />

            <small class="text-danger">{{ errors[0] }}</small>
          </div>
        </validation-provider>

        <div v-if="form.generalShipmentType === null">
          <b-alert variant="info" class="d-flex align-items-center">
            <feather-icon icon="InfoIcon" size="16" class="mr-1" />
            Selecciona el tipo de envío general para ver las opciones que ofrece
            la farmacia
          </b-alert>
        </div>

        <div v-else-if="customerShipmentTypeOptions.length === 0">
          <b-alert show variant="warning" class="d-flex align-items-center">
            <feather-icon icon="AlertTriangleIcon" size="16" class="mr-1" />
            La farmacia no tiene opciones de envío para el tipo seleccionado
          </b-alert>
        </div>

        <validation-provider v-else v-slot="{ errors }" :rules="{ required: true }" class="col-12 col-sm-6">
          <div>
            <RadioGroup label="Opciones disponibles:" :options="customerShipmentTypeOptions"
              v-model="form.customerShipmentType" name="customerShipmentType" radio-label="name" />

            <small class="text-danger">{{ errors[0] }}</small>
          </div>
        </validation-provider>
      </validation-observer>
    </b-card-body>
  </b-card>
</template>

<script>
import { TabContent } from "@components/vue-form-wizard-lazy";

import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@/@core/utils/validations/validations";

import vSelect from "vue-select";

import { mapState, mapActions } from "@/store/deliveries";

import RadioGroup from "@/views/deliveries/components/RadioGroup.vue";

export default {
  components: {
    TabContent,
    vSelect,

    ValidationProvider,
    ValidationObserver,

    RadioGroup,
  },
  data() {
    return {
      generalShipmentTypeOptions: [
        {
          id: 1,
          value: "colectivo",
          label: "Colectivo / grupal",
          description:
            "El envío será realizado cuando la cantidad de pedidos sea suficiente para realizar un envío grupal",
          icon: "UsersIcon",
        },
        {
          id: 2,
          value: "individual",
          label: "Individual",
          description: "El envío será realizado de forma individual",
          icon: "UserIcon",
        },
      ],
      customerShipmentTypeOptions: [],
      form: {
        generalShipmentType: null,
        customerShipmentType: null,
      },
      initializedWatchers: false,
    };
  },
  computed: {
    ...mapState(["customer", "config"]),
  },
  methods: {
    ...mapActions(["updateCustomerShipmentType"]),

    async getCustomerShipmentTypes() {




      const url = `customers/${JSON.parse(localStorage.getItem('dataForm')).customer.id}/shipment-types?shipmentGroup=${this.form.generalShipmentType}`;

      await this.$http
        .get(url)
        .then((response) => {
          const {
            data: { customerShipmentTypes },
          } = response;

          if (customerShipmentTypes?.length > 0) {
            const newOptions = customerShipmentTypes.map((item) => ({
              id: item.id,
              value: item.id,
              name: item.name,
              shipmentTypeId: item.shipmentTypeId,
              group: item.group,
              settingsType: item.settingsType,
            }));

            this.customerShipmentTypeOptions = [...newOptions];
          } else {
            this.customerShipmentTypeOptions = [];
          }
        })
        .catch(() => {
          this.customerShipmentTypeOptions = [];
        });
    },

    async validate() {
      const isValid = await this.$refs.form.validate();

      if (
        isValid &&
        this.form.customerShipmentType === null &&
        this.customerShipmentTypeOptions.length === 0
      ) {
        this.$bvToast.toast(
          "El tipo de envío seleccionado no tiene opciones disponibles, por favor selecciona otro tipo de envío",
          {
            title: "Advertencia",
            variant: "warning",
            solid: true,
          }
        );

        return false;
      }

      return isValid;
    },

    async initializeStep() {
      if (!this.config) {
        this.initializedWatchers = true;
        return;
      }

      this.form.generalShipmentType = this.config?.shipmentGroup || null;
      await this.getCustomerShipmentTypes();
      this.form.customerShipmentType = this.config?.shipmentType || null;

      this.$nextTick(() => {
        this.initializedWatchers = true;
      });
    },
  },
  watch: {
    "form.generalShipmentType"() {
      if (!this.initializedWatchers) return;

      this.form.customerShipmentType = null;

      if (this.form.generalShipmentType) {
        this.getCustomerShipmentTypes();
      }
    },
    "form.customerShipmentType"() {
      if (!this.initializedWatchers) return;

      const foundItem = this.customerShipmentTypeOptions.find(
        (item) => item.id === this.form.customerShipmentType
      );

      const shipmentTypeObj = foundItem
        ? {
          id: foundItem.id,
          shipmentTypeId: foundItem.shipmentTypeId,
          name: foundItem.name,
          group: foundItem.group,
          settingsType: foundItem.settingsType,
        }
        : null;

      this.updateCustomerShipmentType(shipmentTypeObj);
    },
  },

  created() {
    this.initializeStep();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<style scoped>
.shipment-types-container {
  display: flex;
  flex-direction: row;
}

.shipment-types-container label {
  display: flex;
  align-items: center;
  justify-content: space-between;

  gap: 0.5rem;
  width: 100%;
}
</style>
