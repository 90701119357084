var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"border-variant":"primary"}},[_c('b-card-header',[_c('b-card-title',[_c('feather-icon',{staticClass:"mr-1",attrs:{"icon":"MailIcon","size":"20"}}),_vm._v(" Información de contacto ")],1)],1),_c('b-card-body',[_c('b-card-text',[_vm._v(" Ingresa la información de contacto de la persona que recibirá el paquete. ")]),(_vm.personLoadedFromDB)?_c('div',[_c('b-alert',{attrs:{"show":"","variant":"info"}},[_vm._v(" Detectamos que ya has realizado un envío con nosotros, por lo tanto hemos cargado la información de contacto que nos proporcionaste en ese momento. Si deseas modificarla, puedes hacerlo a continuación. ")])],1):_vm._e(),_c('div',{staticClass:"divider"}),_c('validation-observer',{ref:"form"},[_c('form',[_c('b-row',[_c('validation-provider',{staticClass:"col-12 col-sm-6",attrs:{"name":"names","rules":{ required: true, alpha_num_spaces: true, max: 100 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Nombres:"}},[_c('ObfuscatedInput',{attrs:{"placeholder":"John","editable":_vm.editableFields},model:{value:(_vm.person.names),callback:function ($$v) {_vm.$set(_vm.person, "names", $$v)},expression:"person.names"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('validation-provider',{staticClass:"col-12 col-sm-6",attrs:{"name":"surnames","rules":{ required: true, alpha_num_spaces: true, max: 100 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Apellidos:"}},[_c('ObfuscatedInput',{attrs:{"placeholder":"Doe","editable":_vm.editableFields},model:{value:(_vm.person.surnames),callback:function ($$v) {_vm.$set(_vm.person, "surnames", $$v)},expression:"person.surnames"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-row',[_c('validation-provider',{staticClass:"col-12 col-sm-6",attrs:{"name":"email","rules":{ required: false, email: true, regex: /^[^\*]+$/ }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Correo electrónico:"}},[_c('ObfuscatedInput',{attrs:{"placeholder":"example@example.com","editable":_vm.editableFields},model:{value:(_vm.person.email),callback:function ($$v) {_vm.$set(_vm.person, "email", $$v)},expression:"person.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('validation-provider',{staticClass:"col-12 col-sm-6",attrs:{"name":"phone","rules":{ required: true, numeric: true, max: 10 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Teléfono:"}},[_c('ObfuscatedInput',{attrs:{"placeholder":"311 111 1111","editable":_vm.editableFields},model:{value:(_vm.person.phone),callback:function ($$v) {_vm.$set(_vm.person, "phone", $$v)},expression:"person.phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }