<template>
    <b-button 
        :variant="variant" 
        @click="onClick"
        :disabled="loading"
    >
        <slot>
            <div class="d-flex align-items-center gap-2">
                <b-spinner v-if="loading" variant="white" style="margin-bottom:1px" small></b-spinner>
                <slot name="whileNotLoading" v-else>
                    <b-icon v-if="icon" :icon="icon" size="18" />
                </slot>
                {{text}}
            </div>
        </slot>
    </b-button>
</template>

<script>
export default {
    props: {
        click: {
            type: Function,
            default: () => {},
        },

        icon: {
            type: String,
            default: '',
        },

        text: {
            type: String,
            default: 'Click aqui',
        },

        variant: {
            default: 'primary',
            validator: (value) => [
                'primary',
            ].includes(value),
        }
    },

    data(){
        return {
            loading: false,
        }
    },

    methods: {
        onClick() {
            this.loading = true;
            this.click().finally(() => {
                this.loading = false;
            })
        },
    },
}
</script>
