<template>
  <b-card border-variant="primary">
    <b-card-header>
      <b-card-title>
        <feather-icon icon="MailIcon" size="20" class="mr-1" />
        Información de contacto
      </b-card-title>
    </b-card-header>

    <b-card-body>
      <b-card-text>
        Ingresa la información de contacto de la persona que recibirá el
        paquete.
      </b-card-text>

      <div v-if="personLoadedFromDB">
        <b-alert show variant="info">
          Detectamos que ya has realizado un envío con nosotros, por lo tanto
          hemos cargado la información de contacto que nos proporcionaste en ese
          momento. Si deseas modificarla, puedes hacerlo a continuación.
        </b-alert>
      </div>

      <div class="divider" />

      <validation-observer ref="form">
        <form>
          <b-row>
            <validation-provider
              v-slot="{ errors }"
              name="names"
              :rules="{ required: true, alpha_num_spaces: true, max: 100 }"
              class="col-12 col-sm-6"
            >
              <b-form-group label="Nombres:">
                <ObfuscatedInput
                  placeholder="John"
                  v-model="person.names"
                  :editable="editableFields"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

            <validation-provider
              v-slot="{ errors }"
              name="surnames"
              :rules="{ required: true, alpha_num_spaces: true, max: 100 }"
              class="col-12 col-sm-6"
            >
              <b-form-group label="Apellidos:">
                <ObfuscatedInput
                  placeholder="Doe"
                  v-model="person.surnames"
                  :editable="editableFields"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-row>

          <b-row>
            <validation-provider
              v-slot="{ errors }"
              name="email"
              :rules="{ required: false, email: true, regex: /^[^\*]+$/ }"
              class="col-12 col-sm-6"
            >
              <b-form-group label="Correo electrónico:">
                <ObfuscatedInput
                  placeholder="example@example.com"
                  v-model="person.email"
                  :editable="editableFields"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

            <validation-provider
              v-slot="{ errors }"
              name="phone"
              :rules="{ required: true, numeric: true, max: 10 }"
              class="col-12 col-sm-6"
            >
              <b-form-group label="Teléfono:">
                <ObfuscatedInput
                  placeholder="311 111 1111"
                  v-model="person.phone"
                  :editable="editableFields"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-row>
        </form>
      </validation-observer>
    </b-card-body>
  </b-card>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { email, max, numeric, required, regex, alpha_num_spaces } from "@validations";

import { mapState } from "@/store/deliveries";

import { ObfuscatedInput } from "../components";

export default {
  components: {
    // Vee validate
    ValidationObserver,
    ValidationProvider,

    // Custom components
    ObfuscatedInput,
  },
  computed: {
    ...mapState(["person", "personLoadedFromDB"]),
    editableFields() {
      return this.personLoadedFromDB;
    },
  },
  watch: {
    personLoadedFromDB() {
      this.$refs.form.reset();
      this.$forceUpdate();
    },
  },
  methods: {
    async validate() {
      const fields = this.$refs.form.fields;

      const changedFields = Object.values(fields).filter(
        (field) => field.dirty
      );

      if (this.personLoadedFromDB && changedFields.length === 0) {
        return true;
      }

      if (this.personLoadedFromDB && changedFields.length > 0) {
        const invalidFields = changedFields.filter((field) => !field.valid);
        return invalidFields.length === 0;
      }

      const isValid = await this.$refs.form.validate();

      if (!isValid) {
        this.$bvToast.toast(
          "Por favor, verifica que todos los campos estén correctos.",
          {
            title: "Campos inválidos",
            variant: "danger",
            solid: true,
          }
        );
      }

      return isValid;
    },
  },
};
</script>

<style scoped>
.divider {
  border-top: 1px solid #e9ecef;
  margin: 1.5rem 0;
}
</style>
