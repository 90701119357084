<template>
  <div class="outer-container">
    <form-wizard ref="wizard" title="Pedidos" subtitle="Sigue los pasos para realizar tu pedido"
      color="#009ee0" stepSize="xs" class="font-wizard">
      <b-button variant="outline-danger" class="ml-auto d-block mb-1" @click="clearProcess">
        Eliminar proceso
      </b-button>

      <tab-content :title="!action ? 'Pedido' : 'Dirección'" v-if="!action">
        <CumInformationStep />
      </tab-content>

      <!--<tab-content title="Tipo de envío" :lazy="true">
        <ShipmentTypeSelectionStep ref="shipmentTypeSelectionStep" />
      </tab-content>
      -->

      <tab-content title="Dirección" :lazy="true">
        <DeliveryMapStep />
      </tab-content>

      <!--<tab-content title="Fecha de entrega" :lazy="true">
        <BasicShipmentTypeSettingsStep />
      </tab-content>
      -->

      <tab-content title="Identidad" :before-change="() => validate('documentInformationStep')" :lazy="true">
        <DocumentInformationStep ref="documentInformationStep" />
      </tab-content>

      <tab-content title="Contacto" :before-change="() => validate('contactInformationStep')" :lazy="true">
        <ContactInformationStep ref="contactInformationStep" />
      </tab-content>

      <tab-content title="Ordenes" :before-change="() => validate('documentStep')" :lazy="true">
        <DocumentStep ref="documentStep" />
      </tab-content>

      <tab-content title="Resumen" :lazy="true">
        <CumResumeStep />
      </tab-content>

      <template slot="footer" slot-scope="props">
        <div class="buttons-container">
          <b-button v-if="props.activeTabIndex > 0" @click.prevent="props.prevTab" type="button" class="flex-button"
            variant="outline-primary">
            <feather-icon icon="ChevronLeftIcon" size="18" />
            Anterior
          </b-button>

          <b-button v-if="!props.isLastStep" @click.prevent="props.nextTab()" type="button" class="flex-button"
            variant="primary">
            <feather-icon icon="ChevronRightIcon" size="18" />
            Siguiente
          </b-button>

          <LoaderButton v-else class="flex-button" icon="suit-heart-fill" text="Finalizar" :click="_saveInDB" />
        </div>
      </template>
    </form-wizard>
  </div>
</template>

<script>
import { FormWizard, TabContent } from "@components/vue-form-wizard-lazy";
import LoaderButton from "@/components/LoaderButton.vue";

import { debounce } from "lodash";

import {
  ContactInformationStep,
  BasicShipmentTypeSettingsStep,
  ShipmentTypeSelectionStep,
  DocumentInformationStep,
  CumInformationStep,
  DocumentStep,
  CumResumeStep,
  DeliveryMapStep,
} from "./partials";

import { mapState, mapActions } from "@/store/deliveries";

export default {
  components: {
    FormWizard,
    TabContent,

    // Custom components
    ContactInformationStep,
    BasicShipmentTypeSettingsStep,
    ShipmentTypeSelectionStep,
    DocumentInformationStep,
    DocumentStep,
    CumInformationStep,
    DeliveryMapStep,
    CumResumeStep,
    LoaderButton,
  },
  mounted() {
    

    // Cambiar a la segunda pestaña al cargar la página
  },
  data() {
    return {
      removeWatch: null,
      action: false
    };
  },
  computed: {
    ...mapState(["customerShipmentType"]),
  },
  methods: {
    ...mapActions(["saveInStorage", "clearStore", "saveInDB"]),

    getActionForm(){
      let dataForm = localStorage.getItem('dataForm');
      if (dataForm) {
        try {
          dataForm = JSON.parse(dataForm);
          this.action = dataForm.action;
          console.log(this.action); // Aquí puedes usar la variable `action`
        } catch (e) {
          console.error('Error parsing JSON:', e);
        }
      } else {
        console.log('No dataForm found in localStorage');
      }
    },

    async validate(ref) {
      if (!this.$refs[ref]) return false;

      const validation = await this.$refs[ref].validate();

      return validation;
    },

    async clearProcess() {
      this.removeWatch();
      await this.clearStore();
      this.$router.push({ name: "guest" });
    },

    async _saveInDB() {
      await this.saveInDB()
        .then((data) => {
          this.$bvModal.msgBoxOk(
            `El pedido ha sido creado exitosamente con el código ${data.id}, a tu correo te llegará el resumen del mismo. A continuación serás redirigido a la página principal.`,
            {
              title: "Operación exitosa",
              size: "md",
              buttonSize: "sm",
              okVariant: "success",
              centered: true,
            }
          );

          setTimeout(() => {
            this.clearProcess();
          }, 5000);
        })
        .catch((error) => {
          this.$bvModal.msgBoxOk(
            `El siguiente error ha sido detectado: ${error}`,
            {
              title: "Error en los datos",
              size: "md",
              buttonSize: "sm",
              okVariant: "danger",
              centered: true,
            }
          );
        });
    },
  },
  created() {
    this.getActionForm()
    const debouncedSave = debounce(() => {
      this.saveInStorage();
    }, 500);

    this.removeWatch = this.$store.watch(
      (state) => state.deliveries,
      debouncedSave,
      { deep: true }
    );
  },
};
</script>

<style scoped>
.outer-container {
  width: 100%;
  max-width: 1000px;

  margin: 0 auto;
}

.flex-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;

  padding-block: 0.8rem;
  padding-inline: 1.5rem;
  width: 100%;
}

.buttons-container {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: white; /* Cambia esto según el fondo de tu página */
  padding: 10px;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between; /* Ajusta según tus necesidades */
  z-index: 1000; /* Asegura que esté encima de otros elementos */
}

.flex-button {
  margin: 0 10px; /* Ajusta el margen según tus necesidades */
}

@media (max-width: 768px) {
  .font-wizard{
    font-size: 10px;
  }
}
</style>
