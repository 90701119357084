<template>
	<b-card border-variant="primary">
		<div class="d-flex flex-column gap-8">
      <h4 class="text-center">Resumen del perdido</h4>

      <b-container fluid>
        <b-row>
          <b-col cols="12" md="12" sm="12" class="padding-box">
            <ResumeBox icon="house-fill" title="Farmacia" class="border px-2 py-1">
              {{ customer.name }} <br>
              {{ customer.doctypedoc }} <br>
              <b>{{ customer.city }}</b>, <br>
              <small>{{ customer.address }}</small>
            </ResumeBox>
          </b-col>
          <b-col cols="12" md="12" sm="12" class="padding-box">
            <ResumeBox icon="person-fill" title="Usuario" class="border px-2 py-1">
              {{ person.names }} {{ person.surnames }} <br>
              {{ person.documentAcronym }}. {{ person.documentNumber }} <br>

              <b-icon icon="envelope" /> {{ person.email }} <br>
              <b-icon icon="telephone" /> {{ person.phone }}
            </ResumeBox>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="12" sm="12" class="padding-box">
            <ResumeBox icon="geo-alt-fill" title="Envío" class="border px-2 py-1">
              <strong>Dirección:</strong> {{ customerLocation.selectedPlace.formatted_address }}<br>
              <strong>Latitud:</strong> {{ customerLocation.selectedPlace.geometry.location.lat() }}<br>
              <strong>Longitud:</strong> {{ customerLocation.selectedPlace.geometry.location.lng() }}<br>
              <strong>Costo de envío:</strong> $ {{ customerLocation.calculatedCost }}<br>
              <strong>Distancia:</strong> {{ customerLocation.distanceInKm }} km
            </ResumeBox>
          </b-col>
        </b-row>
      </b-container>

			<ResumeBox icon="bag-fill" title="Pedido">
				<b-table :items="showStock" bordered />
			</ResumeBox>
		</div>
    
	</b-card>
</template>

<script>
import { mapState } from "@/store/deliveries";
import ResumeBox from "../components/ResumeBox.vue";

export default {
	components: {
		ResumeBox
	},
	computed: {
		...mapState(['person', 'customer', 'shipmentInfo', 'cumStock', "customerLocation"]),

        $shipmentInfo(){
            return this.shipmentInfo ?? {};
        },

		showStock() {
			return Object.values(this.cumStock).map((stock) => {
				return {
					Cantidad: stock.amount,
					Nombre: stock.cum.producto,
				}
			});
		}
	}
}
</script>
<style >
.padding-box{
  padding: 4px;
  margin: 0;
}
</style>