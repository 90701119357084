<template>
  <div>
    <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/vue-file-agent@latest/dist/vue-file-agent.css" />
    <validation-observer ref="form">
      <b-card border-variant="primary">
        <b-card-header>
          <b-card-title>
            <feather-icon icon="FileIcon" size="20" class="mr-1" />
            Documentos
          </b-card-title>
        </b-card-header>

        <b-row class="mt-3 form-row">

          <!-- <b-col sm="3">
            <validation-provider v-slot="{ errors }" name="Tipo de archivo" :rules="{ required: true }">
              <v-select v-model="fileForm.type" label="title" placeholder="Tipo de archivo" :clearable="true"
                :options="optionFiles" :reduce="(option) => option.id" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col> -->
          <b-col sm="12" class="mb-2">
            <validation-provider v-slot="{ errors }" name="Archivo Order" :rules="{ required: false }">
              <b-form-file v-model="fileForm.file" :accept="'image/*,.pdf'" placeholder="Cargar orden médica obligatoria"
                drop-placeholder="Cargar orden médica obligatoria" browseText="Cargar" :rules="{ required: true }" @change="fileSave(1)"/>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col sm="12">
            <!-- <b-form-input placeholder="Número orden o autorización" v-model="fileForm.number" /> -->
            <validation-provider v-slot="{ errors }" name="Archivo licencia" :rules="{ required: false }">

              <b-form-file v-model="fileForm.fileLicense" :accept="'image/*,.pdf'" placeholder="Autorización opcional"
                drop-placeholder="Autorización opcional" browseText="Cargar" :rules="{ required: false }" @change="fileSave(2)" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>

          </b-col>
          
          <b-col sm="12">

            <b-table class="mt-1" responsive="sm" :items="medicalAuthorizations" :fields="fields">
              <template #cell(type)="data">
                {{ getTypeFile(data) }}
              </template>

              <template #cell(actions)="data">
                <b-button variant="gradient-danger" class="btn-icon" @click="fileDelete(data)">
                  <feather-icon icon="TrashIcon" />
                </b-button>
              </template>
            </b-table>
          </b-col>
        </b-row>

        <b-row class="mt-1">
          <b-col sm="12">
            <h6 class="p-1 text-justify-center" style="text-align: justify;">Recuerda que tus fórmulas deben estar vigentes al
              momento de la entrega y que los medicamentos que requieren autorización son los
              denominados:
              NO PBS (NO POS) y/o ALTO COSTO </h6>
          </b-col>
        </b-row>
      </b-card>
    </validation-observer>
  </div>
</template>
<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { mapState, mapActions, mapMutations } from "@/store/deliveries";
import vSelect from "vue-select";
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BFormInput
} from "bootstrap-vue";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BFormInput,
    ValidationObserver,
    ValidationProvider,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      items: [],
      storeUrl: process.env.VUE_APP_API_URL + '/file-temp/store',
      optionFiles: [
        { id: 'order', title: 'Orden médica' },
        { id: 'license', title: 'Autorización' },
      ],
      fields: [
        { key: 'type', label: 'Tipo' },
        { key: 'name', label: 'Orden Medica' },
        { key: 'name', label: 'Autorización' },
        { key: 'actions', label: 'Acciones' },
      ],
      fileForm: {
        type: null,
        file: null,
        fileLicense: null
      }
    };
  },
  watch: {

  },
  computed: {
    ...mapState(["medicalAuthorizations"]),
  },
  methods: {
    ...mapMutations(["setMedicalAuthorizations"]),
    ...mapActions(["updateMedicalAuthorizations"]),

    async validate() {

      let isValid = await false;

      this.setMedicalAuthorizations(this.medicalAuthorizations);

      //this.$store.dispatch('medicalAuthorizations', this.medicalAuthorizations);

      this.medicalAuthorizations.forEach(authorization => {
        if (authorization.type === 'order') {
          isValid = true;
        }
      });

      if (isValid) {
        return await true;
      } else {
        this.$refs.form.validate();
        
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `Error`,
            icon: 'CoffeeIcon',
            variant: 'danger',
            text: `Debe cargar almenos una orden médica`,
          },
        })
        return await false;
      }
    },

    fileSave(type) {
      this.$refs.form.validate().then(success => {
        if (success) {
          let formData = new FormData();
          formData.append('fileLicense', this.fileForm.fileLicense);
          formData.append('file', this.fileForm.file);
          formData.append('type', type == 1 ? 'order' : 'license');
          this.$http.post(
            '/file-temp/store',
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }
          )
            .then((response) => {
              console.log("response", response);
              this.medicalAuthorizations.push(
                response.data.file
              );
              this.resetForm()
              this.$refs.form.reset()
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Muy Bien!`,
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: `Documento cargado con éxito`,
                },
              })
            })
            .catch(error => {
              console.log('error')
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Error`,
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: `No se pudo cargar el documento`,
                },
              })
            })
        }
      })
    },

    fileDelete(data) {
      this.$http.post('file-temp/delete/' + data.item.id).then(response => {
        const deleteItem = this.medicalAuthorizations.findIndex(object => object.id === data.item.id);
        if (deleteItem !== -1) {
          this.medicalAuthorizations.splice(deleteItem, 1);
        }
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `Archivo eleminado`,
            icon: 'CoffeeIcon',
            variant: 'success',
            text: `El archivo fue eliminado con éxito`,
          },
        })
      })
        .catch(error => {
          console.log('error')
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Error`,
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: `No se pudo eliminar el documento`,
            },
          })
        })
    },

    getTypeFile(data) {
      const type = data.item.type === 'order' ? 'Orden médica' : data.item.type === 'license' ? 'Autorización' : '';
      return type;
    },

    resetForm() {
      this.fileForm = {
        type: null,
        file: null
      }
    },

  },
};
</script>

<style>
.form-row {
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  padding: 10px;
}

.width-100 {
  width: 100% !important;
}
</style>